import axios from "axios";
import { API_ENDPOINT } from "../config";


export const handleForgotPassword = async (data) => {
    const { email} = data
    let fd = new FormData()
    fd.append('email', email)
   
    let res = await axios({
        method: "POST",
        data: fd,
        url: `${process.env.REACT_APP_API_ENDPOINT}user_forgot_password_check`,
        withCredentials: true,
    })
    return res
};