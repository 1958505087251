import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";


// const cookies = new Cookies();
let token = sessionStorage.getItem(process.env.REACT_APP_TOKEN_KEY);



export const downloadReports = async (data) => {
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios(
        {
            method: "POST",
            url: `${process.env.REACT_APP_API_ENDPOINT}reports`,
            responseType: 'blob',
            data: fd,
            headers: { 
                Authorization: token ? `Bearer ${token}` : '', 
                'Content-Type': 'application/json' 
            }

        })
    return res
};