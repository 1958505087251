import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config"; 
import Cookies from "universal-cookie";

// const cookies = new Cookies();
let token = sessionStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

export const getServiceList = async () => {
    // let token = cookies.get(TOKEN_KEY);
    let res = await axios({ method: "GET", url: `${process.env.REACT_APP_API_ENDPOINT}servicelist`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};

export const addService = async (data) => {
    let fd = new FormData()
    for (var key in data) {
        if(Array.isArray(data[key])){
            fd.append(key, JSON.stringify(data[key]));
        }else{
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data:fd, url: `${process.env.REACT_APP_API_ENDPOINT}servicecreate`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};
export const updateService = async (data) => {
    let fd = new FormData()
    for (var key in data) {
        if(Array.isArray(data[key])){
            fd.append(key, JSON.stringify(data[key]));
        }else{
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data:fd, url: `${process.env.REACT_APP_API_ENDPOINT}serviceupdate`,headers: { Authorization: token ? `Bearer ${token}` : '', }})
    return res
};

export const handleUpdateServiceStatus = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    const {id,home} = data
    let fd = new FormData()
    fd.append('id',id)
    fd.append('home',home)
    let res = await axios({ method: "POST", data:fd, url: `${process.env.REACT_APP_API_ENDPOINT}servicestatus`,headers: { Authorization: token ? `Bearer ${token}` : '', }},)
    return res
};

export const deleteService = async (id) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    fd.append('id', id)
    let res = await axios({ method: "POST", data: fd, url: `${process.env.REACT_APP_API_ENDPOINT}servicedelete`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
