import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import React, { useRef, useState } from "react";
import { useQueryClient, useMutation } from "@tanstack/react-query";
import { handleUpdateProductStatus } from "../../../apis/product";
import Spinner from "../../../components/spinner";
import Errors from "../../errors";
import { Link, useSearchParams } from "react-router-dom";
import useRead from "../../../hooks/useRead";
import Pagination from "../../../components/elements/pagination";
import { FiDownload, FiUpload, FiX } from "react-icons/fi";
import { RiDeleteBinLine } from "react-icons/ri";
import useUploadfile from "../../../hooks/useUploadfile";
import useDownload from "../../../hooks/useDownload";
import DeleteModal from "../../../components/elements/DeleteModal";
import useDeleteProduct from "../../../hooks/products/useDeleteProduct";


function Products() {
  const uploadRef = useRef(null);
  const { setDataToServer } = useUploadfile({
    // pass necessary options
  });
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);
    setDataToServer.mutate({ excel_file: file }); // Trigger the mutation with the selected file
  };

  const [fileName, setFileName] = useState("");
  const showFileName = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("");
    }
  };

  const clearFileName = () => {
    setFileName("");
    // Optionally, you might want to clear the selected file from the input
    if (uploadRef.current) {
      uploadRef.current.value = "";
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();
  let initialData = {
    pageNo: searchParams.get("pageNo") || 0,
    limit: searchParams.get("limit") || 50,
    searchQuery: searchParams.get("searchQuery") || "",
    category_id: searchParams.get("category_id") || "",
  };
  const { data, paramsObject, setGetListParams } = useRead({
    url: "product_listing",
    initialData,
    method: "GET",
  });
  const handleSearchVal = (e) => {
    let value = e.target.value;
    let copyPrams = {
      ...paramsObject,
      searchQuery: value,
    };
    setSearchParams(copyPrams);
    setGetListParams(copyPrams);
    // setSearcVal(value)
  };
  const handlePageClick = (val) => {
    let pageNo = {
      ...paramsObject,
      pageNo: val.selected,
    };
    setGetListParams(pageNo);
    setSearchParams(pageNo);
  };

  const handleSetLimit = (e) => {
    let value = e.target.value;
    let copyPrams = {
      ...paramsObject,
      limit: value,
    };
    setSearchParams(copyPrams);
    setGetListParams(copyPrams);
  };
  const [products, setProducts] = useState(null);
  const [isDeleteModalOpen,setDeleteModalOpen] = useState(false)
  const handleDelete = (productId) => {
    setProducts(productId);
    setDeleteModalOpen(true)
  };
  const {handleSubmit} = useDeleteProduct()
  const { downloadFile } = useDownload({
    url: 'product_excel_templete_download', // Your download URL
    data: { /* any data you need to send with the POST request */ },
    onSuccess: () => console.log('File downloaded successfully'),
    onError: (err) => console.error('Error downloading file', err)
});

  return (
    <>
      <div className="category px-4">
        <div className="category__head headingBorder__b">
          <h2 className="titleHeading">Products </h2>
        </div>
        <div className="category_table py-5 space-y-5">
          <div className="flex items-end space-x-4">
            <div className="flex-1">
              <input
                onChange={handleSearchVal}
                value={paramsObject.searchQuery}
                type="text"
                placeholder="Search By Key Word...."
                className="input"
              />
            </div>
            <div>
              <Link to={`add-product`} className="block">
                <Button
                  variant="outlined"
                  startIcon={<AddIcon />}
                  className="primaryBtn-outlined"
                >
                  Add Product
                </Button>
              </Link>
            </div>
            {/* <div class="flex flex-col justify-center items-center underline-offset-1 underline gap-2">
            <button className="flex gap-2 items-center" onClick={downloadFile}
           >
        Download 
      </button>
            
              <input
                ref={uploadRef}
                onChange={handleFileChange}
                type="file"
                accept=".xls, .xlsx"
                id="fileInput"
                className="hidden"
              />
            </div> */}
            {/* <div class="flex flex-col justify-center items-center underline-offset-1 underline gap-2">
              <button
                className="flex gap-2 items-center "
                
                type="submit"
                onClick={() => uploadRef.current.click()}
              >
                <FiUpload />
                Upload
              </button>
           
              <input
                ref={uploadRef}
                onChange={handleFileChange}
                type="file"
                accept=".xls, .xlsx"
                id="fileInput"
                className="hidden"
              />
            </div> */}
            {fileName && (
              <div className="flex gap-2 items-center">
                <p>{fileName}</p>
                <button onClick={clearFileName}>
                  <FiX />
                </button>
              </div>
            )}
            <div>
              <label htmlFor="" className="label">
                Limit
              </label>
              <select
                name="limit"
                value={data.data?.per_page}
                className="input"
                onChange={handleSetLimit}
              >
                {Array(5)
                  .fill(1)
                  .map((_, idx) => {
                    return (
                      <option key={idx} value={(idx + 1) * 10}>
                        {(idx + 1) * 10}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          {data.isLoading || data.isFetching ? (
            <Spinner />
          ) : data.status === "error" ? (
            <Errors errorObject={data.error} inline />
          ) : (
            <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
              <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                <thead>
                  <tr className="text-left">
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Name
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Category
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Product Code
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      SKU
                    </th>
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Price
                    </th>
                    {/* <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Status
                    </th> */}
                    <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.data?.data?.map((productData, idx) => {
                    const {
                      image_path,
                      product_code,
                      name,
                      category_name,
                      product_id,
                      status,
                      id,
                      offer_price,
                      quantity,
                      sku_code,
                    } = productData;
                    return (
                      <tr key={idx}>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {name}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {category_name}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {product_code}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {sku_code ? sku_code : ""}
                          </span>
                        </td>
                        <td className="border-dashed border-t border-gray-200 firstName">
                          <span className="text-gray-700 px-6 py-3 flex items-center text-sm">
                            {offer_price ? offer_price :""}
                          </span>
                        </td>
                        {/* <td className="border-dashed border-t border-gray-200 emailAddress">
                          <div className="flex items-center justify-center">
                            {status}
                          </div>
                        </td> */}
                        <td className="border-dashed border-t border-gray-200 emailAddress">
                          <div className="flex items-center justify-center">
                            <Link to={`edit-product/${product_id}`}>
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </Link>
                            <RiDeleteBinLine
                              onClick={() => handleDelete(product_id)}
                              className="text-lg cursor-pointer text-gray-600"
                            />
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <div>
            <Pagination
              currentPage={+paramsObject.pageNo}
              lengthofItems={data.data?.total_count}
              limit={paramsObject.limit}
              onPageChange={handlePageClick}
            />
          </div>
        </div>
      </div>
      <DeleteModal
      open={isDeleteModalOpen} 
      handleClose={() => setDeleteModalOpen(false)}
      title={"Delete Product"}
      message={"Are you sure you want to delete this Product?"}
      onDelete={() => handleSubmit({product_id:products})}
      />
    </>
  );
}

export default Products;
