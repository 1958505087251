import axios from "axios";
import { API_ENDPOINT, TOKEN_KEY } from "../config";
import Cookies from "universal-cookie";

// const cookies = new Cookies();
let token = sessionStorage.getItem(process.env.REACT_APP_TOKEN_KEY);

export const getProductList = async () => {
    // let token = cookies.get(TOKEN_KEY);
    let res = await axios({ method: "POST", url: `${process.env.REACT_APP_API_ENDPOINT}productlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};


export const handleUpdateProductStatus = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let res = await axios({ method: "POST", data: fd, url: `${process.env.REACT_APP_API_ENDPOINT}productstatus`, headers: { Authorization: token ? `Bearer ${token}` : '', } },)
    return res
};

export const addProduct = async (data) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    for (var key in data) {
        if (key === 'images_of_product') {
            for (let i = 0; i < data['images_of_product'].length; i++) {
                fd.append('images_of_product[]', data['images_of_product'][i]);
            }
        }
        else if (Array.isArray(data[key])) {
            fd.append(key, JSON.stringify(data[key]));
        } else {
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data: fd, url: `${process.env.REACT_APP_API_ENDPOINT}productcreate`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const editProduct = async (data) => {
    let fd = new FormData()
    for (var key in data) {
        if (key === 'images_of_product') {
            for (let i = 0; i < data['images_of_product'].length; i++) {
                fd.append('images_of_product[]', data['images_of_product'][i]);
            }
        }
        else if (Array.isArray(data[key])) {
            fd.append(key, JSON.stringify(data[key]));
        } else {
            fd.append(key, data[key]);
        }
    }
    let res = await axios({ method: "POST", data: fd, url: `${process.env.REACT_APP_API_ENDPOINT}productupdate`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};

export const deleteProduct = async (id) => {
    // let token = cookies.get(TOKEN_KEY);
    let fd = new FormData()
    fd.append('id', id)
    let res = await axios({ method: "POST", data: fd, url: `${process.env.REACT_APP_API_ENDPOINT}productdelete`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};